<template>
  <div v-if="currentRole === 4 || currentRole === 5">
    <v-row no-gutters>
      <v-col md="12">
        <div class="pa-10">
          <h2 class="mb-4">Achievements & Rewards</h2>
          <v-row>
            <v-col md="3" :class="'d-flex arch-select'">
              <v-select
                :items="centerList"
                item-text="location"
                item-value="locationID"
                append-icon="keyboard_arrow_down"
                outlined
                dense
                label="Center"
                hide-details
                placeholder="Center"
                v-model="locationID"
                @change="changeLocation"
              >
              </v-select>
              <v-select
                :items="classStatusList"
                item-text="classStatus"
                item-value="classStatusID"
                append-icon="keyboard_arrow_down"
                outlined
                dense
                label="Class status"
                hide-details
                placeholder="Class status"
                v-model="classStatusID"
                @change="changeClassStatus"
                class="select-width"
              >
              </v-select>
              <v-text-field
                @input="changeFrom"
                type="date"
                label="From date"
                v-model="fromDate"
                outlined
                dense
                hide-details
              />
              <v-text-field
                @input="changeTo"
                type="date"
                label="To date"
                v-model="toDate"
                outlined
                dense
                hide-details
              />
            </v-col>
          </v-row>

          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="primary"
            class="my-10"
          ></v-progress-linear>

          <v-tabs v-model="tab">
            <v-tab
              ><span class="text-normal mt-5" style="text-transform: none; font-size: 16px">
                View by students
              </span>
            </v-tab>
            <v-tab
              ><span class="text-normal mt-5" style="text-transform: none; font-size: 16px">
                View by classes</span
              >
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item
              ><RewardByStudent
                :locationID="locationID"
                :fromDate="fromDate"
                :toDate="toDate"
                :rewardListType="rewardListType"
                :rewardListTypeUpdate="rewardListTypeUpdate"
                :classStatusID="classStatusID"
              />
            </v-tab-item>
            <v-tab-item
              ><RewardByClass
                :locationID="locationID"
                :fromDate="fromDate"
                :toDate="toDate"
                :rewardListType="rewardListType"
                :rewardListTypeUpdate="rewardListTypeUpdate"
                :classStatusID="classStatusID"
              />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import RewardByStudent from './RewardByStudent.vue'
import RewardByClass from './RewardByClass.vue'
import { rewardApi } from '@/api/reward.js'
import { class_managerment } from '@/api/class-management'
export default {
  components: { RewardByStudent, RewardByClass },
  data() {
    return {
      tab: 0,
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      isLoading: false,
      fromDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      toDate: dayjs().format('YYYY-MM-DD'),
      locationID: JSON.parse(localStorage.getItem('currentCenter')),
      centerList: JSON.parse(localStorage.getItem('currentUser')).locationPermissions,
      rewardListType: [],
      rewardListTypeUpdate: [],
      classStatusList: [],
      classStatusID: 3,
    }
  },
  created() {
    if (localStorage.getItem('rewardLocation')) {
      this.locationID = JSON.parse(localStorage.getItem('rewardLocation'))
    }
    if (localStorage.getItem('rewardFromDate')) {
      this.fromDate = dayjs(localStorage.getItem('rewardFromDate')).format('YYYY-MM-DD')
    }
    if (localStorage.getItem('rewardToDate')) {
      this.toDate = dayjs(localStorage.getItem('rewardToDate')).format('YYYY-MM-DD')
    }
    if (localStorage.getItem('rewardClassStatus')) {
      this.classStatusID = JSON.parse(localStorage.getItem('rewardClassStatus'))
    }
    this.getRewardTypeList()
  },
  methods: {
    changeTo() {
      localStorage.setItem('rewardToDate', this.toDate)
    },
    changeFrom() {
      localStorage.setItem('rewardFromDate', this.fromDate)
    },
    changeLocation() {
      localStorage.setItem('rewardLocation', this.locationID)
    },
    changeClassStatus() {
      localStorage.setItem('rewardClassStatus', this.classStatusID)
    },
    async init() {
      this.isLoading = false
    },

    async getRewardTypeList() {
      this.loading = true
      await rewardApi.getRewardTypeList().then(res => {
        if (res && res.length > 0) {
          this.rewardListType = res

          this.rewardListTypeUpdate = res.reduce((object, item) => {
            object[item.id] = item.name
            return object
          }, {})
          this.rewardTypeID = res[0].id
        }
        this.loading = false
      })

      await class_managerment.getClassStatus().then(res => {
        if (res) {
          res.unshift({
            classStatusID: 6,
            classStatus: 'All',
          })
          this.classStatusList = res
        }
      })
    },
    _dayjs: dayjs,
  },
}
</script>
<style>
</style>
